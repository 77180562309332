export default function Home() {
    return (
        <main className="home">
            <div>
                <h1>Greg Ward</h1>
                <div className="animationWrap">
                <div className="animatedText"></div>
                </div>
            </div>
        </main>
    )
}

